import React, { Component } from 'react';

import Header from '../../components/Header/Header';
import Modal from '../../components/Modal/Modal';
import Footer from '../../components/Footer/Footer';

import Frontpage from '../../components/Frontpage/Frontpage';
import Cardpage from '../../components/Cardpage/Cardpage';
import Infopage from '../../components/Infopage/Infopage';

import { menuItems, modalItems } from '../../data/menu.js';
import { pages } from '../../data/pages.js';

import './App.scss';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuItems: menuItems,
      menuStatus: 'closed',
      modalContent: modalItems.uitleg,
      modalStatus: 'closed',
      pages: pages,
      page: pages[0],
      history: [0]
      }
  }

  handleClickHamburger= (event) => {
    let status = (this.state.menuStatus === "closed") ? "open" : "closed";
    this.setState({ menuStatus: status});
  }

  handleClickMenu = (event) => {
    event.preventDefault();
    this.setState({
      modalStatus: 'open',
      modalContent: modalItems[event.target.id],
      menuStatus: 'closed'
    });
  }

  handleClickModal = (event) => {
    this.setState({
      modalStatus: 'closed',
    });
  }

  handleOpenPage = (pageNumber) => {
    const page = this.state.pages.filter(page => page.id === pageNumber)[0];
    const history = this.state.history;
    history.push(pageNumber);
    this.setState({ history, page, menuStatus: 'closed' });
  }

  handleClickBack = () => {
    const history = this.state.history,
          pageNumber = history[history.length-2],
          page = this.state.pages.filter(page => page.id === pageNumber)[0];
    history.pop();
    this.setState({ history, page, menuStatus: 'closed' });
  }

  render() {

    let pageComponent = null;
    switch (this.state.page.template) {
      case 'cardpage':
        pageComponent = <Cardpage page={this.state.page} handleClickCard={this.handleOpenPage} />
        break;
      case 'infopage':
        pageComponent = <Infopage page={this.state.page} />
        break;
      default:
        pageComponent = <Frontpage page={this.state.page} handleOpenPage={this.handleOpenPage} />
        break;
    }

    const appClass = (this.state.modalStatus === 'open') ? 'app modalOpen' : 'app';

    return (
      <div className={appClass}>
        <Header
          showBackButton={this.state.page.id !== 0}
          handleClickBack={this.handleClickBack}
          handleOpenPage={this.handleOpenPage}
          handleClickHamburger={this.handleClickHamburger}
          items={this.state.menuItems}
          status={this.state.menuStatus}
          handleClickMenu={this.handleClickMenu}
        />
        {pageComponent}
        <Modal
          content={this.state.modalContent}
          status={this.state.modalStatus}
          handleClickModal={this.handleClickModal}
        />
        <Footer />
      </div>
    );
  }
}

export default App;
