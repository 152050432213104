import React from 'react';
import ReactDOM from 'react-dom';
import App from './containers/App/App';
import { unregister } from './registerServiceWorker';
import './index.scss';
import './styles/fonts.scss';

ReactDOM.render(<App />, document.getElementById('root'));

unregister()
