import React from 'react';

import geslachtsorgaanDonkerblauw from './geslachtsorgaanDonkerblauw.svg';

export const menuItems = [{
    id: 'uitleg',
    name: 'Uitleg'
}, {
    id: 'info',
    name: 'Meer informatie'
}, {
    id: 'begrippen',
    name: 'Begrippen'
}, {
    id: 'colofon',
    name: 'Colofon'
}];

export const modalItems = {
  uitleg: {
    title: 'Wat is deze Keuzehulp?',
    text: <div>
        <p>Deze Keuzehulp informeert je over de verschillende opties voor vruchtbaarheidsbehoud. Je bespreekt met je behandelend arts voor welke behandelingen je in aanmerking komt en wat de verschillende opties inhouden. De mogelijkheden voor vruchtbaarheidsbehoud verschillen van persoon tot persoon en zijn afhankelijk van kenmerken van je ziekte. Je oncoloog en gynaecoloog bespreken samen voor welke opties je in aanmerking komt.</p>
        <p>De Keuzehulp vertelt niet wat voor jou de beste keuze is of wat je moet doen, maar is gemaakt om jou en je omgeving te ondersteunen. De Keuzehulp helpt bij het maken van keuzes voor of tegen vruchtbaarheidsbehoud en zo ja, welke behandeling. Je kunt met je arts bespreken welke argumenten voor jou belangrijk zijn en welk resultaat je van de behandeling kunt verwachten. De wetenschap levert nieuwe inzichten en mogelijkheden op, de situatie kan in de toekomst veranderen.</p>
        <p>Deze Keuzehulp is gemaakt in samenwerking met de academische ziekenhuizen VUmc en AMC, met inbreng van ervaringsdeskundigen.</p>
      </div>
    },
  info: { title: 'Wat moet ik nog meer weten?', text: <div>
    <h6>Zijn er nog andere opties voor vruchtbaarheidsbehoud?</h6>
    <p>Een behandeling die in deze Keuzehulp niet beargumenteerd wordt, is een behandeling met GNRH-antagonisten. Deze behandeling legt de productie van je hormonen stil, waardoor de eisprong uitblijft. Dit zou kunnen helpen om je eicellen te beschermen tegen schadelijke effecten van de kankerbehandeling. Uit literatuur is vooralsnog nog niet gebleken dat deze behandeling zinvol is, hierom is hij niet opgenomen in de Keuzehulp.</p>

      <h6>Waar kan ik terecht voor meer informatie?</h6>
      <ul>
        <li><a href='https://www.vumc.nl/afdelingen/poli-klinische-zorgeenheden/polikliniek-specifiek/v/voortplaningsgeneeskunde/'target='_blank' rel='noopener noreferrer'>Voortplantingsgeneeskunde VUmc</a>
            <p>Bezoekadres: Polikliniek receptie H, De Boelelaan 1118, 1081 HZ Amsterdam</p>
            <p>E-mail:  <a href='mailto:vp.poliVG@VUMC.nl'>vp.poliVG@VUMC.nl</a></p>
            <p>Telefoonnummer: 020 444 1190<br />(tussen 10:00-12:00, buiten deze tijden word je na inspreken de volgende dag terug gebeld)</p>
        </li>
        <li><a href='https://www.amc.nl/cvv'target='_blank' rel='noopener noreferrer'>Centrum voor Voortplantingsgeneeskunde AMC</a><br />
            <p>Adres: Centrum voor Voortplantingsgeneeskunde, AMC - Q3-119, Postbus 22660, 1100 DD Amsterdam</p>
            <p>Bezoekadres:  AMC, Q4</p>
            <p>E-mail: <a href='mailto:cvv@amc.nl'>cvv@amc.nl</a></p>
            <p>Telefoonnummer: 020 566 4287<br />((maandag tot en met vrijdag van 9.00 tot 11.00 en van 14.00 tot 16.00)</p></li>
        <li><a href='http://nnf-info.nl/' target='_blank' rel='noopener noreferrer'>Nederlands Netwerk Fertiliteitspreservatie</a></li>
        <li><a href='https://www.freya.nl/kinderwens/diagnoses/kanker-en-vruchtbaarheid/' target='_blank' rel='noopener noreferrer'>Freya - patiëntenvereniging voor mensen met vruchtbaarheidsproblemen</a></li>
        <li><a href='http://www.borstkankerenkinderwens.nl/' target='_blank' rel='noopener noreferrer'>Borstkanker en kinderwens (LUMC)</a></li>
        <li><a href='https://aya4net.nl/ik-en-mijn/vruchtbaarheid-zwangerschap/' target='_blank' rel='noopener noreferrer'>Stichting  Nationaal AYA ‘Jong & Kanker’ Platform</a></li>
        <li><a href='http://oncofertility.northwestern.edu/' target='_blank' rel='noopener noreferrer'>Oncofertility consortium</a></li>
    </ul>
    </div>
  },
  begrippen: { title: 'Wat betekenen belangrijke begrippen?', text:
    <span>
    <img src={geslachtsorgaanDonkerblauw} alt="Vrouwelijk geslachtsorgaan" className="diagram"/>
    <dl>
      <dt>Baarmoeder (Uterus)</dt>
      <dd>Een orgaan waarin de zwangerschap groeit.</dd>
      <dt>Complicatie</dt>
      <dd>Een onbedoelde bijwerking of letsel van een medische behandeling.</dd>
      <dt>Eiblaasje (Follikel)</dt>
      <dd>Een soort vochtblaasje in de eierstok waarin de eicel ontwikkelt/groeit/rijpt.</dd>
      <dt>Eicel (Oöcyt)</dt>
      <dd>Vrouwelijke geslachtscel in de eierstok.</dd>
      <dt>Eierstok (Ovarium)</dt>
      <dd>Twee organen aan beide kanten van de baarmoeder waarin eicellen liggen opgeslagen.</dd>
      <dt>Eierstokweefsel (Cortex)</dt>
      <dd>In de buitenste laag van de eierstokken (cortex) liggen eicellen opgeslagen.</dd>
      <dt>Eileider (Tuba)</dt>
      <dd>Buisvormig orgaan waardoor zaadcellen en embryo zich verplaatsen tussen eierstok en baarmoeder.</dd>
      <dt>Embryo</dt>
      <dd>Een prille zwangerschap (de eerste drie maanden).</dd>
      <dt>Hormoonstimulatie</dt>
      <dd>Injecties met vrouwelijke hormonen om de groei van eiblaasjes te stimuleren.</dd>
      <dt>ICSI-behandeling</dt>
      <dd>Een eicel wordt in het laboratorium bevrucht met een zaadcel. De embryo's worden ingevroren en opgeslagen om later eventueel in de baarmoeder te plaatsen.</dd>
      <dt>IVF-behandeling</dt>
      <dd>Een eicel wordt in het laboratorium met zaadcellen samengevoegd waarna een bevruchting plaats kan vinden. De embryo's worden ingevroren en opgeslagen om later eventueel in de baarmoeder te plaatsen.</dd>
      <dt>Kijkoperatie (Laparoscopie)</dt>
      <dd>De dokter opereert via kleine sneetjes in je lichaam.</dd>
      <dt>Oncoloog</dt>
      <dd>Medisch specialist die kanker behandelt.</dd>
      <dt>Punctie</dt>
      <dd>De dokter prikt met een naald door de vagina om eicellen op te zuigen uit de eierstok.</dd>
      <dt>Vaginale echo</dt>
      <dd>De dokter maakt een echo via de vagina.</dd>
      <dt>Vruchtbaarheidsbehoud (Fertiliteitspreservatie)</dt>
      <dd>Het opslaan van geslachtscellen om de kans op een genetisch eigen kind op een later tijdstip te vergroten.</dd>
      <dt>Zaadcel (Semen)</dt>
      <dd>Mannelijke geslachtscel.</dd>
    </dl>
    </span>
},
  colofon: { title: 'Hoe is deze Keuzehulp tot stand gekomen?', text:
    <div>
      <p>Deze Keuzehulp is gemaakt in opdracht van het Vrouw Kind Centrum van het UMCA (VUmc en AMC, Amsterdam).</p>
      <p>De inhoud van deze Keuzehulp is tot stand gekomen tijdens acht denksessies met ervaringsdeskundigen, medisch specialisten en een psycholoog. Wij danken hen allen voor hun denkwerk.</p>
      <p>De Keuzehulp is nog niet verspreid over alle ziekenhuizen in Nederland. Het kan zijn dat je arts de Keuzehulp (nog) niet kent. Je behandelend arts kan bij vragen contact opnemen met het VUmc of AMC.</p>
      <p><a href='https://www.argumentenfabriek.nl/' target='_blank' rel='noopener noreferrer'>De Argumentenfabriek</a> heeft de Keuzehulp ontwikkeld en vormgegeven.</p>
      <p>© 2018 De Argumentenfabriek</p>
    </div>
  }
}
