import React from 'react';

const LogoUmc = () => (
<svg viewBox="0 0 550 90" className="logoUmc">
<g fill="#ffff00">
	<g>
		<path fill="#003741" d="M109,18.8c-8.8,0-15.1,6.2-15.1,14.9v22.7c0,0.9,0.7,1.6,1.6,1.6h1.9c0.9,0,1.6-0.7,1.6-1.6V44.2h20.2v12.1 c0,0.9,0.7,1.6,1.6,1.6h1.9c0.9,0,1.6-0.7,1.6-1.6V33.6C124.1,24.9,117.9,18.8,109,18.8 M119.1,38.7H98.9v-5.1 c0-5.9,4.1-9.8,10.1-9.8c6.1,0,10.1,3.8,10.1,9.8V38.7z"/>
		<path fill="#003741" d="M159.5,27.9c-3.7,0-6.9,1.5-9,4.3c-2.1-2.7-5.3-4.3-9-4.3c-6.6,0-11.5,5-11.5,11.5v16.9 c0,0.9,0.7,1.6,1.6,1.6h1.9c0.9,0,1.6-0.7,1.6-1.6V39.4c0-3.8,2.7-6.5,6.5-6.5c3.8,0,6.5,2.7,6.5,6.5v16.9c0,0.9,0.7,1.6,1.6,1.6 h1.9c0.9,0,1.6-0.7,1.6-1.6V39.4c0-3.8,2.7-6.5,6.5-6.5c3.8,0,6.5,2.7,6.5,6.5v16.9c0,0.9,0.7,1.6,1.6,1.6h1.9 c0.9,0,1.6-0.7,1.6-1.6V39.4C171.1,32.7,166.2,27.9,159.5,27.9"/>
		<path fill="#003741" d="M189.8,39.8c-6.1-0.9-7.2-1.8-7.3-3.3c0-0.7,0.3-1.3,0.8-1.9c1.1-1.1,3.2-1.8,5.6-1.8c2.7,0,6.1,0.8,7.3,4.4 c0.2,0.7,0.8,1.1,1.5,1.1h1.9c0.5,0,0.9-0.2,1.2-0.6c0.3-0.4,0.4-0.9,0.3-1.3c-1.3-5.4-5.8-8.5-12.3-8.5c-7.5,0-11.5,4.4-11.5,8.7 c0.1,6.5,6.9,7.5,11.6,8.1c7.1,1,7.7,2.7,7.8,4.3c0.1,0.9-0.2,1.8-0.8,2.4c-1.3,1.3-3.6,2.1-6.2,2.1c-2.8,0-7.7-0.7-8.7-5.2 c-0.2-0.7-0.8-1.2-1.5-1.2h-1.9c-0.5,0-0.9,0.2-1.2,0.6c-0.3,0.4-0.4,0.8-0.4,1.3c1,6,6.1,9.6,13.7,9.6c4.1,0,7.6-1.3,9.8-3.5 c1.5-1.6,2.3-3.8,2.3-6.1c0,0,0,0,0,0C201.5,41.6,193.6,40.4,189.8,39.8"/>
		<path fill="#003741" d="M218.1,33.6c0.9,0,1.6-0.7,1.6-1.6v-1.9c0-0.9-0.7-1.6-1.6-1.6h-5.5v-4c0-0.9-0.7-1.6-1.6-1.6h-1.9 c-0.9,0-1.6,0.7-1.6,1.6v4h-2c-0.9,0-1.6,0.7-1.6,1.6V32c0,0.9,0.7,1.6,1.6,1.6h2v13.7c0,3.6,1.1,6.5,3.2,8.4 c1.6,1.5,3.8,2.3,6.3,2.3c0.4,0,0.8,0,1.1-0.1c0.8-0.1,1.4-0.7,1.4-1.6v-1.7c0-0.4-0.2-0.8-0.5-1.1c-0.3-0.3-0.7-0.4-1.1-0.4 c-1.8,0.1-3.1-0.3-4-1.2c-1.2-1.2-1.5-3.2-1.5-4.6V33.6H218.1z"/>
		<path fill="#003741" d="M270,28c-3.5-0.4-6.7,0.4-9.3,2.3v-0.2c0-0.9-0.7-1.6-1.6-1.6h-1.9c-0.9,0-1.6,0.7-1.6,1.6v26.2 c0,0.9,0.7,1.6,1.6,1.6h1.9c0.9,0,1.6-0.7,1.6-1.6V40.5c0-2.3,0.9-4.5,2.4-5.9c1.5-1.4,3.8-2,6.5-1.8c0.5,0,0.9-0.1,1.2-0.4 c0.3-0.3,0.5-0.7,0.5-1.2v-1.7C271.4,28.8,270.8,28.1,270,28"/>
		<path fill="#003741" d="M369.2,27.9c-3.7,0-6.9,1.5-9,4.3c-2.1-2.7-5.3-4.3-9-4.3c-6.6,0-11.5,5-11.5,11.5v16.9 c0,0.9,0.7,1.6,1.6,1.6h1.9c0.9,0,1.6-0.7,1.6-1.6V39.4c0-3.8,2.7-6.5,6.5-6.5c3.8,0,6.5,2.7,6.5,6.5v16.9c0,0.9,0.7,1.6,1.6,1.6 h1.9c0.9,0,1.6-0.7,1.6-1.6V39.4c0-3.8,2.7-6.5,6.5-6.5c3.8,0,6.5,2.7,6.5,6.5v16.9c0,0.9,0.7,1.6,1.6,1.6h1.9 c0.9,0,1.6-0.7,1.6-1.6V39.4C380.8,32.7,375.9,27.9,369.2,27.9"/>
		<path fill="#003741" d="M428.6,19.4h-1.9c-0.9,0-1.6,0.7-1.6,1.6v22.7c0,5.9-4,9.8-10.1,9.8c-6,0-10.1-3.9-10.1-9.8V21 c0-0.9-0.7-1.6-1.6-1.6h-1.9c-0.9,0-1.6,0.7-1.6,1.6v22.7c0,8.6,6.4,14.9,15.1,14.9c8.9,0,15.1-6.1,15.1-14.9V21 C430.2,20.1,429.5,19.4,428.6,19.4"/>
		<path fill="#003741" d="M470.5,18.5c-4.3,0-8.1,2.1-10.5,5.5c-2.4-3.4-6.3-5.5-10.5-5.5c-7.2,0-13,5.8-13,13v24.8 c0,0.9,0.7,1.6,1.6,1.6h1.9c0.9,0,1.6-0.7,1.6-1.6V31.5c0-4.4,3.6-8,8-8c4.4,0,8,3.6,8,8v24.8c0,0.9,0.7,1.6,1.6,1.6h1.9 c0.9,0,1.6-0.7,1.6-1.6V31.5c0-4.5,3.5-8,8-8c4.4,0,8,3.6,8,8v24.8c0,0.9,0.7,1.6,1.6,1.6h1.9c0.9,0,1.6-0.7,1.6-1.6V31.5 C483.5,24.4,477.7,18.5,470.5,18.5"/>
		<path fill="#003741" d="M333.3,35.5c-1.3-2.3-3.2-4.2-5.4-5.5c-2.3-1.3-4.8-2-7.6-2c-2.8,0-5.4,0.7-7.7,2c-2.3,1.3-4.1,3.2-5.5,5.5 c-1.3,2.3-2,4.9-2,7.7c0,2.8,0.6,5.4,1.8,7.7c1.2,2.3,2.9,4.1,5,5.5c2.1,1.3,4.5,2,7.2,2c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.3,0 c1.7,0,3.4-0.3,5-0.9c0.2-0.1,0.4-0.1,0.6-0.2c0.4-0.1,0.8-0.4,1.2-0.6c0.1,0,0.2-0.1,0.3-0.1c0,0,0,0,0,0 c1.2-0.7,2.3-1.6,3.3-2.6v2.6c0,0.9,0.7,1.6,1.6,1.6h2c0.9,0,1.6-0.7,1.6-1.6v-1V45.1v-1.9C335.3,40.4,334.6,37.8,333.3,35.5  M328.6,48.4c-0.8,1.5-2,2.8-3.5,3.6c-1.5,0.9-3.1,1.3-4.9,1.3c-1.8,0-3.5-0.4-4.9-1.3c-1.5-0.9-2.6-2.1-3.5-3.6 c-0.8-1.5-1.3-3.3-1.3-5.2c0-1.9,0.4-3.7,1.3-5.2c0.8-1.5,2-2.8,3.5-3.7c1.5-0.9,3.1-1.3,4.9-1.3c1.8,0,3.5,0.4,4.9,1.3 c1.5,0.9,2.6,2.1,3.5,3.7c0.8,1.6,1.3,3.3,1.3,5.2C329.8,45.2,329.4,46.9,328.6,48.4"/>
		<path fill="#003741" d="M299.9,16.9H298c-0.9,0-1.6,0.7-1.6,1.6v13.3c-2.6-2.5-6-3.9-9.6-3.9c-8.1,0-14.7,6.9-14.7,15.4 c0,7.9,5.8,14.4,13.3,15.1l0,0c0.5,0,0.9,0.1,1.4,0.1c0.5,0,0.9,0,1.4-0.1c7.4-0.7,13.2-6.9,13.3-14.4l0,0V18.5 C301.4,17.6,300.7,16.9,299.9,16.9 M277.1,43.3c0-5.7,4.3-10.3,9.6-10.3c5.3,0,9.6,4.6,9.6,10.3c0,5.6-4.3,10.2-9.6,10.2 C281.5,53.5,277.1,48.9,277.1,43.3"/>
		<path fill="#003741" d="M245.7,50.1c-0.6-0.4-1.5-0.4-2,0.2c-1.6,1.9-3.9,2.8-7,2.8c-5.1,0-9.1-3.7-9.8-8.7h22.6 c0.9,0,1.6-0.7,1.6-1.5c0.1-4-1.6-8.1-4.5-11c-2.7-2.8-6.3-4.3-10.1-4.3c-8.1,0-14.7,6.9-14.7,15.4c0,8.5,6.6,15.2,15,15.2 c4.6,0,8.4-1.6,10.9-4.7c0.6-0.7,0.4-1.7-0.3-2.2L245.7,50.1z M236.4,32.7c4.1,0,7.7,2.8,9,6.8h-18.1 C228.7,35.4,232.2,32.7,236.4,32.7"/>
		<path fill="#003741" d="M521.5,46.2c-0.7-0.4-1.6-0.2-2,0.5c-2.6,4.2-6.9,6.8-11.7,6.8c-7.7,0-14-6.7-14-15c0-8.2,6.3-14.9,14-14.9 c5,0,9.5,2.7,11.9,7.1c0.4,0.7,1.3,0.9,1.9,0.5l1.9-1c0.8-0.4,1-1.3,0.6-2.1c-3.3-5.9-9.4-9.6-16.3-9.6c-10.5,0-19,9-19,20 c0,11,8.5,20,19,20c6.5,0,12.5-3.6,16-9.2c0.4-0.7,0.2-1.6-0.5-2L521.5,46.2z"/>
		<path fill="#F07814" d="M127.8,71.2H127c-0.3,0-0.5,0.2-0.5,0.5v1.5c0,0.3,0.2,0.5,0.5,0.5h0.9c0.3,0,0.5-0.2,0.5-0.5v-1.5 C128.4,71.5,128.1,71.2,127.8,71.2"/>
		<path fill="#F07814" d="M183.5,71.2h-0.9c-0.3,0-0.5,0.2-0.5,0.5v1.5c0,0.3,0.2,0.5,0.5,0.5h0.9c0.3,0,0.5-0.2,0.5-0.5v-1.5 C184,71.5,183.8,71.2,183.5,71.2"/>
		<path fill="#F07814" d="M212.5,71.2h-0.9c-0.3,0-0.5,0.2-0.5,0.5v1.5c0,0.3,0.2,0.5,0.5,0.5h0.9c0.3,0,0.5-0.2,0.5-0.5v-1.5 C213,71.5,212.8,71.2,212.5,71.2"/>
		<path fill="#F07814" d="M288.4,71.2h-0.9c-0.3,0-0.5,0.2-0.5,0.5v1.5c0,0.3,0.2,0.5,0.5,0.5h0.9c0.3,0,0.5-0.2,0.5-0.5v-1.5 C288.9,71.5,288.7,71.2,288.4,71.2"/>
		<path fill="#F07814" d="M106.7,71.2h-0.9c-0.3,0-0.5,0.2-0.5,0.5V82c0,2.8-1.9,4.6-4.8,4.6c-2.8,0-4.8-1.9-4.8-4.6V71.7 c0-0.3-0.2-0.5-0.5-0.5h-0.9c-0.3,0-0.5,0.2-0.5,0.5V82c0,3.8,2.8,6.6,6.7,6.6c3.9,0,6.7-2.7,6.7-6.6V71.7 C107.3,71.5,107,71.2,106.7,71.2"/>
		<path fill="#F07814" d="M116.9,75.1c-1.5,0-2.8,0.5-3.8,1.4v-0.5c0-0.3-0.2-0.5-0.5-0.5h-0.9c-0.3,0-0.5,0.2-0.5,0.5v11.9 c0,0.3,0.2,0.5,0.5,0.5h0.9c0.3,0,0.5-0.2,0.5-0.5v-7.2c0-2.1,1.6-3.6,3.7-3.6c2.2,0,3.5,1.3,3.5,3.6v7.2c0,0.3,0.2,0.5,0.5,0.5 h0.9c0.3,0,0.5-0.2,0.5-0.5v-7.2C122.3,77.2,120.2,75.1,116.9,75.1"/>
		<path fill="#F07814" d="M127.8,75.4H127c-0.3,0-0.5,0.2-0.5,0.5v11.9c0,0.3,0.2,0.5,0.5,0.5h0.9c0.3,0,0.5-0.2,0.5-0.5V75.9 C128.4,75.6,128.1,75.4,127.8,75.4"/>
		<path fill="#F07814" d="M141.6,75.4h-0.9c-0.2,0-0.4,0.1-0.5,0.3l-3.6,9.4l-3.6-9.4c-0.1-0.2-0.3-0.3-0.5-0.3h-0.9 c-0.2,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.1,0.3-0.1,0.5l4.6,11.9c0.1,0.2,0.3,0.3,0.5,0.3h0.9c0.2,0,0.4-0.1,0.5-0.3l4.6-11.9 c0.1-0.2,0-0.3-0.1-0.5C142,75.4,141.8,75.4,141.6,75.4"/>
		<path fill="#F07814" d="M150,75.1c-3.6,0-6.5,3.1-6.5,6.8c0,3.8,2.9,6.7,6.6,6.7c3,0,5.1-1.5,6-4.3c0.1-0.2,0-0.3-0.1-0.5 c-0.1-0.1-0.3-0.2-0.4-0.2h-0.9c-0.2,0-0.4,0.1-0.5,0.3c-0.7,1.8-2.1,2.8-4.1,2.8c-2.5,0-4.4-1.8-4.7-4.3h10.5 c0.3,0,0.5-0.2,0.5-0.5c0-1.7-0.7-3.6-2-4.9C153.2,75.7,151.6,75.1,150,75.1 M145.6,80.4c0.6-2,2.3-3.4,4.3-3.4 c2,0,3.8,1.4,4.4,3.4H145.6z"/>
		<path fill="#F07814" d="M165.8,75.1c-1.7-0.2-3.2,0.2-4.4,1.2v-0.4c0-0.3-0.2-0.5-0.5-0.5H160c-0.3,0-0.5,0.2-0.5,0.5v11.9 c0,0.3,0.2,0.5,0.5,0.5h0.9c0.3,0,0.5-0.2,0.5-0.5v-7.2c0-1.1,0.4-2.1,1.2-2.8c0.7-0.7,1.8-1,3.1-0.9c0.1,0,0.3,0,0.4-0.1 c0.1-0.1,0.2-0.2,0.2-0.4v-0.8C166.2,75.4,166,75.1,165.8,75.1"/>
		<path fill="#F07814" d="M173.4,80.5c-2.9-0.4-3.4-0.9-3.4-1.7c0-0.4,0.1-0.7,0.4-1c0.6-0.6,1.5-0.9,2.7-0.9c1.3,0,2.9,0.4,3.5,2.1 c0.1,0.2,0.3,0.4,0.5,0.4h0.9c0.2,0,0.3-0.1,0.4-0.2c0.1-0.1,0.1-0.3,0.1-0.4c-0.6-2.4-2.5-3.7-5.4-3.7c-3.3,0-5,1.9-5,3.8 c0.1,2.8,2.9,3.2,5.1,3.5c3,0.4,3.6,1.2,3.7,2.1c0,0.5-0.1,0.9-0.4,1.2c-0.6,0.6-1.7,1-2.9,1c-1.3,0-3.7-0.3-4.1-2.5 c-0.1-0.2-0.3-0.4-0.5-0.4h-0.9c-0.2,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.1,0.3-0.1,0.4c0.4,2.6,2.7,4.2,6,4.2c1.8,0,3.3-0.5,4.3-1.5 c0.6-0.7,1-1.6,1-2.6v0C178.6,81.2,175.2,80.7,173.4,80.5"/>
		<path fill="#F07814" d="M183.5,75.4h-0.9c-0.3,0-0.5,0.2-0.5,0.5v11.9c0,0.3,0.2,0.5,0.5,0.5h0.9c0.3,0,0.5-0.2,0.5-0.5V75.9 C184,75.6,183.8,75.4,183.5,75.4"/>
		<path fill="#F07814" d="M193,75.4h-2.7v-2c0-0.3-0.2-0.5-0.5-0.5h-0.9c-0.3,0-0.5,0.2-0.5,0.5v2h-1.1c-0.3,0-0.5,0.2-0.5,0.5v0.9 c0,0.3,0.2,0.5,0.5,0.5h1.1v6.4c0,1.6,0.5,2.8,1.4,3.7c0.7,0.7,1.7,1,2.7,1c0.2,0,0.3,0,0.5,0c0.3,0,0.5-0.3,0.5-0.5V87 c0-0.1-0.1-0.3-0.2-0.4c-0.1-0.1-0.2-0.1-0.4-0.1c-0.8,0-1.5-0.2-1.9-0.6c-0.5-0.5-0.7-1.2-0.7-2.2v-6.4h2.7 c0.3,0,0.5-0.2,0.5-0.5v-0.9C193.5,75.6,193.3,75.4,193,75.4"/>
		<path fill="#F07814" d="M206.9,85.8v-6.7c0-2.5-1.9-4.1-5.2-4.1c-2.9,0-4.9,1.4-5.5,3.7c0,0.2,0,0.3,0.1,0.5c0.1,0.1,0.3,0.2,0.4,0.2 h0.9c0.2,0,0.4-0.2,0.5-0.4c0.6-1.8,2.3-2.1,3.6-2.1c1.5,0,3.3,0.4,3.3,2.2c0,0.9-1.2,1.2-3.9,1.5c-1.7,0.2-5.5,0.5-5.5,4.1 c0,2.6,2.5,3.9,4.8,3.9c1.9,0,3.5-0.5,4.7-1.5c0.1,0.4,0.2,0.7,0.3,0.9c0.1,0.2,0.3,0.3,0.5,0.3h0.9c0.2,0,0.3-0.1,0.4-0.2 c0.1-0.1,0.1-0.3,0.1-0.5C207,87.2,206.9,86.3,206.9,85.8 M200.4,86.6c0,0-2.9,0-2.9-2c0-0.9,0.3-1.9,3.7-2.2 c1.3-0.1,2.7-0.2,3.7-0.7v1.8C205,85.6,202.7,86.6,200.4,86.6"/>
		<path fill="#F07814" d="M433.6,85.8v-6.7c0-2.5-1.9-4.1-5.2-4.1c-2.9,0-4.9,1.4-5.5,3.7c0,0.2,0,0.3,0.1,0.5c0.1,0.1,0.3,0.2,0.4,0.2 h0.9c0.2,0,0.4-0.2,0.5-0.4c0.6-1.8,2.3-2.1,3.6-2.1c1.5,0,3.3,0.4,3.3,2.2c0,0.9-1.2,1.2-3.9,1.5c-1.7,0.2-5.5,0.5-5.5,4.1 c0,2.6,2.5,3.9,4.8,3.9c1.9,0,3.5-0.5,4.7-1.5c0.1,0.4,0.2,0.7,0.3,0.9c0.1,0.2,0.3,0.3,0.5,0.3h0.9c0.2,0,0.3-0.1,0.4-0.2 c0.1-0.1,0.1-0.3,0.1-0.5C433.8,87.2,433.6,86.3,433.6,85.8 M427.1,86.6c0,0-2.9,0-2.9-2c0-0.9,0.3-1.9,3.7-2.2 c1.3-0.1,2.7-0.2,3.7-0.7v1.8C431.7,85.6,429.4,86.6,427.1,86.6"/>
		<path fill="#F07814" d="M212.5,75.4h-0.9c-0.3,0-0.5,0.2-0.5,0.5v11.9c0,0.3,0.2,0.5,0.5,0.5h0.9c0.3,0,0.5-0.2,0.5-0.5V75.9 C213,75.6,212.8,75.4,212.5,75.4"/>
		<path fill="#F07814" d="M223.5,75.1c-1.7-0.2-3.2,0.2-4.4,1.2v-0.4c0-0.3-0.2-0.5-0.5-0.5h-0.9c-0.3,0-0.5,0.2-0.5,0.5v11.9 c0,0.3,0.2,0.5,0.5,0.5h0.9c0.3,0,0.5-0.2,0.5-0.5v-7.2c0-1.1,0.4-2.1,1.2-2.8c0.7-0.7,1.8-1,3.1-0.9c0.1,0,0.3,0,0.4-0.1 c0.1-0.1,0.2-0.2,0.2-0.4v-0.8C224,75.4,223.8,75.1,223.5,75.1"/>
		<path fill="#F07814" d="M251.1,71.2h-0.9c-0.2,0-0.4,0.1-0.5,0.3l-6.1,13.9l-6.1-13.9c-0.1-0.2-0.3-0.3-0.5-0.3h-0.9 c-0.3,0-0.5,0.2-0.5,0.5v16c0,0.3,0.2,0.5,0.5,0.5h0.9c0.3,0,0.5-0.2,0.5-0.5V76.3l5.2,11.7c0.1,0.2,0.3,0.3,0.5,0.3h0.9 c0.2,0,0.4-0.1,0.5-0.3l5.1-11.7v11.5c0,0.3,0.2,0.5,0.5,0.5h0.9c0.3,0,0.5-0.2,0.5-0.5v-16C251.6,71.5,251.4,71.2,251.1,71.2"/>
		<path fill="#F07814" d="M261.4,75.1c-3.6,0-6.5,3.1-6.5,6.8c0,3.8,2.9,6.7,6.6,6.7c3,0,5.1-1.5,6-4.3c0.1-0.2,0-0.3-0.1-0.5 c-0.1-0.1-0.3-0.2-0.4-0.2h-0.9c-0.2,0-0.4,0.1-0.5,0.3c-0.7,1.8-2.1,2.8-4.1,2.8c-2.5,0-4.4-1.8-4.7-4.3h10.5 c0.3,0,0.5-0.2,0.5-0.5c0-1.7-0.7-3.6-2-4.9C264.6,75.7,263,75.1,261.4,75.1 M257,80.4c0.6-2,2.3-3.4,4.3-3.4c2,0,3.8,1.4,4.4,3.4 H257z"/>
		<path fill="#F07814" d="M340.1,75.1c-3.6,0-6.5,3.1-6.5,6.8c0,3.8,2.9,6.7,6.6,6.7c3,0,5.1-1.5,6-4.3c0.1-0.2,0-0.3-0.1-0.5 c-0.1-0.1-0.3-0.2-0.4-0.2h-0.9c-0.2,0-0.4,0.1-0.5,0.3c-0.7,1.8-2.1,2.8-4.1,2.8c-2.5,0-4.4-1.8-4.7-4.3H346 c0.3,0,0.5-0.2,0.5-0.5c0-1.7-0.7-3.6-2-4.9C343.3,75.7,341.7,75.1,340.1,75.1 M335.7,80.4c0.6-2,2.3-3.4,4.3-3.4 c2,0,3.8,1.4,4.4,3.4H335.7z"/>
		<path fill="#F07814" d="M282.3,70.1h-0.9c-0.3,0-0.5,0.2-0.5,0.5v6.5c-1.2-1.3-2.8-2-4.6-2c-3.6,0-6.5,3.1-6.5,6.8 c0,3.7,2.9,6.7,6.5,6.7c1.7,0,3.4-0.7,4.6-2v1.2c0,0.3,0.2,0.5,0.5,0.5h0.9c0.3,0,0.5-0.2,0.5-0.5V70.6 C282.8,70.3,282.5,70.1,282.3,70.1 M276.3,86.7c-2.5,0-4.5-2.2-4.5-4.8c0-2.7,2-4.9,4.5-4.9c2.5,0,4.6,2.2,4.6,4.9 C280.9,84.5,278.8,86.7,276.3,86.7"/>
		<path fill="#F07814" d="M288.4,75.4h-0.9c-0.3,0-0.5,0.2-0.5,0.5v11.9c0,0.3,0.2,0.5,0.5,0.5h0.9c0.3,0,0.5-0.2,0.5-0.5V75.9 C288.9,75.6,288.7,75.4,288.4,75.4"/>
		<path fill="#F07814" d="M298.2,80.5c-2.9-0.4-3.4-0.9-3.4-1.7c0-0.4,0.1-0.7,0.4-1c0.6-0.6,1.5-0.9,2.7-0.9c1.3,0,2.9,0.4,3.5,2.1 c0.1,0.2,0.3,0.4,0.5,0.4h0.9c0.2,0,0.3-0.1,0.4-0.2c0.1-0.1,0.1-0.3,0.1-0.4c-0.6-2.4-2.5-3.7-5.4-3.7c-3.3,0-5,1.9-5,3.8 c0.1,2.8,2.9,3.2,5.1,3.5c3,0.4,3.6,1.2,3.7,2.1c0,0.5-0.1,0.9-0.4,1.2c-0.6,0.6-1.7,1-2.9,1c-1.3,0-3.6-0.3-4.1-2.5 c-0.1-0.2-0.3-0.4-0.5-0.4h-0.9c-0.2,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.1,0.3-0.1,0.4c0.4,2.6,2.7,4.2,6,4.2c1.8,0,3.3-0.5,4.3-1.5 c0.6-0.7,1-1.6,1-2.6v0C303.4,81.2,300,80.7,298.2,80.5"/>
		<path fill="#F07814" d="M325.3,75.1c-1.5,0-2.8,0.5-3.8,1.4v-5.8c0-0.3-0.2-0.5-0.5-0.5h-0.9c-0.3,0-0.5,0.2-0.5,0.5v17.2 c0,0.3,0.2,0.5,0.5,0.5h0.9c0.3,0,0.5-0.2,0.5-0.5v-7.2c0-2.1,1.6-3.6,3.7-3.6c2.2,0,3.5,1.3,3.5,3.6v7.2c0,0.3,0.2,0.5,0.5,0.5 h0.9c0.3,0,0.5-0.2,0.5-0.5v-7.2C330.7,77.2,328.7,75.1,325.3,75.1"/>
		<path fill="#F07814" d="M380.8,75.1c-3.6,0-6.5,3.1-6.5,6.8c0,3.8,2.9,6.7,6.6,6.7c3,0,5.1-1.5,6-4.3c0.1-0.2,0-0.3-0.1-0.5 c-0.1-0.1-0.3-0.2-0.4-0.2h-0.9c-0.2,0-0.4,0.1-0.5,0.3c-0.7,1.8-2.1,2.8-4.1,2.8c-2.5,0-4.4-1.8-4.7-4.3h10.5 c0.3,0,0.5-0.2,0.5-0.5c0-1.7-0.7-3.6-2-4.9C384.1,75.7,382.5,75.1,380.8,75.1 M376.5,80.4c0.6-2,2.3-3.4,4.3-3.4 c2,0,3.8,1.4,4.4,3.4H376.5z"/>
		<path fill="#F07814" d="M396,75.1c-1.5,0-2.8,0.5-3.8,1.4v-0.5c0-0.3-0.2-0.5-0.5-0.5h-0.9c-0.3,0-0.5,0.2-0.5,0.5v11.9 c0,0.3,0.2,0.5,0.5,0.5h0.9c0.3,0,0.5-0.2,0.5-0.5v-7.2c0-2.1,1.6-3.6,3.7-3.6c2.2,0,3.5,1.3,3.5,3.6v7.2c0,0.3,0.2,0.5,0.5,0.5 h0.9c0.3,0,0.5-0.2,0.5-0.5v-7.2C401.4,77.2,399.4,75.1,396,75.1"/>
		<path fill="#F07814" d="M410.2,75.4h-2.7v-2c0-0.3-0.2-0.5-0.5-0.5h-0.9c-0.3,0-0.5,0.2-0.5,0.5v2h-1.1c-0.3,0-0.5,0.2-0.5,0.5v0.9 c0,0.3,0.2,0.5,0.5,0.5h1.1v6.4c0,1.6,0.5,2.8,1.4,3.7c0.7,0.7,1.7,1,2.7,1c0.2,0,0.3,0,0.5,0c0.3,0,0.5-0.3,0.5-0.5V87 c0-0.1-0.1-0.3-0.2-0.4c-0.1-0.1-0.2-0.1-0.4-0.1c-0.8,0-1.5-0.2-1.9-0.6c-0.5-0.5-0.7-1.2-0.7-2.2v-6.4h2.7 c0.3,0,0.5-0.2,0.5-0.5v-0.9C410.7,75.6,410.5,75.4,410.2,75.4"/>
		<path fill="#F07814" d="M420.2,75.1c-1.7-0.2-3.2,0.2-4.4,1.2v-0.4c0-0.3-0.2-0.5-0.5-0.5h-0.9c-0.3,0-0.5,0.2-0.5,0.5v11.9 c0,0.3,0.2,0.5,0.5,0.5h0.9c0.3,0,0.5-0.2,0.5-0.5v-7.2c0-1.1,0.4-2.1,1.2-2.8c0.7-0.7,1.8-1,3.1-0.9c0.1,0,0.3,0,0.4-0.1 c0.1-0.1,0.2-0.2,0.2-0.4v-0.8C420.7,75.4,420.5,75.1,420.2,75.1"/>
		<path fill="#F07814" d="M371.6,84.8c-0.2-0.2-0.5-0.2-0.7,0c-1.2,1.2-2.7,1.8-4.4,1.8c-3.6,0-6.5-3.1-6.5-7c0-3.8,2.9-7,6.5-7 c1.8,0,3.4,0.7,4.6,2c0.2,0.2,0.5,0.2,0.7,0l0.7-0.7c0.2-0.2,0.2-0.5,0-0.7c-1.5-1.6-3.7-2.6-6-2.6c-4.7,0-8.6,4-8.6,8.9 c0,4.9,4,8.9,8.6,8.9c2.2,0,4.2-0.9,5.8-2.4c0.2-0.2,0.2-0.5,0-0.7L371.6,84.8z"/>
		<path fill="#F07814" d="M316.4,85.1c-0.2-0.2-0.5-0.2-0.7,0c-0.8,1-2,1.5-3.4,1.5c-2.5,0-4.5-2.2-4.5-4.8c0-2.7,2-4.9,4.5-4.9 c1.4,0,2.5,0.6,3.3,1.5c0.2,0.2,0.5,0.2,0.7,0l0.7-0.7c0.2-0.2,0.2-0.5,0-0.7c-1.2-1.4-2.8-2.2-4.8-2.2c-3.6,0-6.5,3.1-6.5,6.8 c0,3.7,2.9,6.7,6.5,6.7c1.9,0,3.6-0.8,4.8-2.1c0.2-0.2,0.1-0.5,0-0.7L316.4,85.1z"/>
		<path fill="#E6000F" d="M19.1,13.6v31.1c0,11.9,2.1,17.4,6,20.7c2.2,1.8,5.1,3.1,8.4,4.3V41.9C33.4,30.3,27.8,20.1,19.1,13.6"/>
		<path fill="#E6000F" d="M52.5,13.6v31.1c0,11.9-2.1,17.4-6,20.7c-2.2,1.8-5.1,3.1-8.4,4.3V41.9C38.2,30.3,43.8,20.1,52.5,13.6"/>
		<path fill="#F07814" d="M35.8,88.6c19.7,0,35.8-15.7,35.8-36.4l0-45.5c-5.2,0.3-10.1,1.7-14.4,4v37.6c0,25-18,25.8-21.4,25.8 c-3.3,0-21.4-0.9-21.4-25.8V10.7C10.1,8.4,5.2,7,0,6.7l0,45.5C0,72.9,16.1,88.6,35.8,88.6"/>
		<path fill="#003741" d="M35.8,24.8c2.5-5.1,6.1-9.6,10.5-13.2C43.7,7,40.1,3,35.8,0c-4.3,3-7.9,7-10.5,11.6 C29.6,15.2,33.3,19.7,35.8,24.8"/>
	</g>
</g>
</svg>
);


export default LogoUmc;