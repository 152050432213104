import React from 'react';

import harmonica1 from './harmonica-1-opereren.js';
import harmonica2 from './harmonica-2-eicellen.js';
import harmonica3 from './harmonica-3-embryos.js';
import harmonica4 from './harmonica-4-eierstokweefsel.js';
import harmonica5 from './harmonica-5-eierstok.js';

import geslachtsorgaanBlauw from './geslachtsorgaanBlauw.svg';
import geslachtsorgaanGroen from './geslachtsorgaanGroen.svg';
import geslachtsorgaanOranje from './geslachtsorgaanOranje.svg';
import geslachtsorgaanPaars from './geslachtsorgaanPaars.svg';

export const pages = [{
    id: 0,
    template: 'frontpage',
    text: 'Deze Keuzehulp helpt bij het maken van keuzes over vruchtbaarheidsbehoud. De verschillende opties en belangrijkste argumenten staan overzichtelijk geordend. Deze Keuzehulp helpt bij het gesprek met arts of betrokkenen maar is géén instrument waaruit een beslissing volgt.',
    title: 'Wat zijn voor mij opties voor vruchtbaarheidsbehoud?',
    cards: [
        { id: 'card1', title: 'Eicellen invriezen', text: '', link: 2 },
        { id: 'card2', title: 'Embryo\'s invriezen', text: '', link: 3 },
        { id: 'card3', title: 'Eierstokweefsel invriezen', text: '', link: 4 },
        { id: 'card4', title: 'Eierstok verplaatsen ', text: '', link: 5 }
    ]
}, {
    id: 1,
    slug: 'operaties',
    template: 'infopage',
    intro: {
        expandable: true,
        title: 'Toelichting',
        content:
            <div className="panel-box">
                <p>De keuze voor een behandeling voor vruchtbaarheid behoud is een optie, het is geen medische noodzaak. Het hangt van de individuele voorkeur, medische conditie en situatie af of je hiervoor wil kiezen. In veel situaties is niet met zekerheid te zeggen of de behandeling van kanker ook werkelijk tot verminderde vruchtbaarheid zal leiden.</p>
            </div>
    },
    harmonica: harmonica1,
    expandables: [0,1,2],
    closed: [1,2]
}, {
    id: 2,
    template: 'infopage',
    slug: 'eicellen',
    title: '',
    intro:  {
        expandable: true,
        title: 'Wat houdt het invriezen van eicellen in?',
        content:
        <div className="panel-box">
        <img src={geslachtsorgaanOranje} alt="Vrouwelijk geslachtsorgaan" className="diagram"/>
        <ul>
            <li>Je moet jezelf twee tot drie weken onderhuids hormonen inspuiten om eiblaasjes te laten groeien.</li>
            <li>Een arts of verpleegkundige maakt meerdere vaginale echo's om te kijken of de eiblaasjes goed groeien.</li>
            <li>Als de eiblaasjes groot genoeg zijn haalt een arts ze met een naald via de vagina uit de eierstok.</li>
            <li>Tijdens de ingreep ben je wel wakker, maar vooraf krijg je een kalmerend en pijnstillend middel.</li>
            <li>Alleen de rijpe eicellen worden ingevroren.</li>
            <li>Na de kankerbehandeling kunnen, als een natuurlijke zwangerschap uitblijft, de eicellen worden ontdooid.</li>
            <li>De eicellen worden bevrucht in het laboratorium met zaadcellen van je partner of een donor.</li>
            <li>De embryo's worden in je baarmoeder geplaatst.</li>
        </ul></div>
        },
    voorwaarden: {
        expandable: true,
        title: 'Wat zijn de voorwaarden voor het invriezen van eicellen?',
        content:
        <div className="panel-box">
        <ul>
            <li>Er moet tijd zijn voor je kankerbehandeling start.</li>
            <li>Je oncoloog moet toestemming geven voor dit traject.</li>
            <li>Je mag voor de behandeling niet ouder zijn dan 40 jaar, je kan je eicellen gebruiken tot 50 jaar.</li>
            <li>Je moet lichamelijk en psychisch in staat zijn om meerdere vaginale echo's en een punctie te ondergaan.</li>
        </ul></div>
    },
    harmonica: harmonica2,
    expandables: [0,1,2],
    closed: [0,1,2]
}, {
    id: 3,
    template: 'infopage',
    slug: 'embryos',
    title: '',
    intro:  {
        expandable: true,
        title: 'Wat houdt het invriezen van embryo\'s in?',
        content:
        <div className="panel-box">
        <img src={geslachtsorgaanBlauw} alt="Vrouwelijk geslachtsorgaan" className="diagram"/>
        <ul>
            <li>Je moet jezelf twee tot drie weken onderhuids hormonen inspuiten om eiblaasjes te laten groeien.</li>
            <li>Een arts of verpleegkundige maakt meerdere vaginale echo's om te kijken of de eiblaasjes goed groeien.</li>
            <li>Als de eiblaasjes groot genoeg zijn haalt een arts ze met een naald via je vagina uit de eierstok.</li>
            <li>Tijdens de ingreep ben je wel wakker, maar vooraf krijg je een kalmerend en pijnstillend middel.</li>
            <li>Alleen de rijpe eicellen worden bevrucht in het laboratorium, waarna de embryo's worden ingevroren.</li>
            <li>Na de kankerbehandeling kunnen, als een natuurlijke zwangerschap uitblijft, de embryo's worden ontdooid.</li>
            <li>De embryo's worden in je baarmoeder geplaatst.</li>
        </ul></div>
        },
    voorwaarden: {
        expandable: true,
        title: 'Wat zijn de voorwaarden voor het invriezen van embryo\'s?',
        content:
        <div className="panel-box">
        <ul>
        <li>Er moet tijd zijn voor je kankerbehandeling start.</li>
        <li>Je oncoloog moet toestemming geven voor dit traject.</li>
        <li>Je moet een mannelijke partner of donor hebben die toestemt met behandeling en plaatsing van de embryo's.</li>
        <li>Je mag voor de behandeling niet ouder zijn dan 40 jaar, je kan je embryo's gebruiken tot 50 jaar.</li>
        <li>Je moet lichamelijk en psychisch in staat zijn om meerdere vaginale echo's en een punctie te ondergaan.</li>
        </ul></div>
    },
    harmonica: harmonica3,
    expandables: [0,1,2],
    closed: [0,1,2]
}, {
    id: 4,
    template: 'infopage',
    slug: 'weefsel',
    title: '',
    intro:  {
        expandable: true,
        title: 'Wat houdt het invriezen van eierstokweefsel in?',
        content:
        <div className="panel-box">
        <img src={geslachtsorgaanGroen} alt="Vrouwelijk geslachtsorgaan" className="diagram"/>
        <p>Deze behandeling is experimenteel en bevindt zich nog in de onderzoeksfase. </p>
        <ul>
            <li>Met een kijkoperatie verwijdert een arts een van je eierstokken.</li>
            <li>De ingreep wordt meestal gecombineerd met een al geplande narcose.</li>
            <li>De buitenste laag van de eierstok bevat onrijpe eicellen, deze laag wordt in delen ingevroren.</li>
            <li>Met een kijkoperatie plaatst een arts stukjes ontdooid eierstokweefsel op de achtergebleven eierstok of op een andere plek in je buikholte.</li>
            <li>Na terugplaatsing moet blijken of een natuurlijke zwangerschap tot stand komt of dat een IVF-behandeling noodzakelijk is.</li>
            <li>Het is in de toekomst wellicht mogelijk om eicellen te verkrijgen uit het weefsel in het laboratorium.</li>
            <li>Het eierstokweefsel kan niet bij een ander worden teruggeplaatst, met eicellen zou dit wel kunnen.</li>
        </ul></div>
        },
    voorwaarden: {
        expandable: true,
        title: 'Wat zijn de voorwaarden voor het invriezen van eierstokweefsel?',
        content:
        <div className="panel-box">
        <ul>
            <li>Het terugplaatsen van het weefsel kan alleen als de kans nihil wordt geacht dat er kankercellen in het eierstokweefsel zitten.</li>
        </ul></div>
    },
    harmonica: harmonica4,
    expandables: [0,1,2],
    closed: [0,1,2]
}, {
    id: 5,
    template: 'infopage',
    slug: 'eierstok',
    title: '',
    intro:  {
        expandable: true,
        title: 'Wat houdt het verplaatsen van een eierstok in?',
        content:
        <div className="panel-box">
        <img src={geslachtsorgaanPaars} alt="Vrouwelijk geslachtsorgaan" className="diagram"/>
        <p>Deze behandeling is experimenteel en bevindt zich nog in de onderzoeksfase. De behandeling gebeurt alleen als je bestraling op je onderbuik krijgt.</p>
        <ul>
            <li>Met een operatie verplaatst een arts je eierstok naar een hoger gelegen deel van je buikholte.</li>
            <li>De arts maakt je eierstok vast aan de buikwand.</li>
            <li>Na de kankerbehandeling komt je menstruele cyclus meestal weer op gang.</li>
            <li>Je hebt de grootste kans op zwangerschap via een IVF-behandeling met een punctie via de buik.</li>
            <li>Als je baarmoeder is beschadigd door bestraling heb je een draagmoeder nodig.</li>
        </ul></div>
        },
    voorwaarden: {
        expandable: true,
        title: 'Wat zijn de voorwaarden voor het verplaatsen van een eierstok?',
        content:
        <div className="panel-box">
        <ul>
            <li>Je krijgt bestraling in je onderbuik.</li>
            <li>Je mag voor deze behandeling geen uitzaaiingen hebben.</li>
            <li>Deze optie komt pas aan bod als de andere opties zijn afgevallen, of in combinatie met andere opties.</li>
        </ul></div>
    },
    harmonica: harmonica5,
    expandables: [0,1,2],
    closed: [0,1,2]
}

];
