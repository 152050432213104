const data = {"text": "Wat zijn voor mij argumenten voor en tegen eierstokweefsel invriezen?","items": [{
    "text": "Resultaat",
    "items": [{
        "text": "Argumenten voor",
        "items": [{
            "text": "De behandeling geeft me de kans een genetisch eigen kind te krijgen",
            "items": [{
                "text": "Ik vergroot mijn kans op een kind."
            }, {
                "text": "Ik kan potentieel met het eierstokweefsel meer eicellen invriezen dan als ze via een punctie worden verkregen."
            }]
        }, {
            "text": "De behandeling kan meer opleveren dan we nu denken",
            "items": [{
                "text": "Als de techniek zich ontwikkelt zijn er mogelijk manieren om mijn eicellen te laten rijpen buiten mijn lichaam."
            }]
        }]
    }, {
        "text": "Argumenten tegen",
        "items": [{
            "text": "De behandeling is experimenteel en het resultaat onzeker",
            "items": [{
                "text": "Het is onzeker of ik met deze behandeling zwanger kan worden."
            }, {
                "text": "Het is onzeker of ik het weefsel kan gebruiken omdat de behandeling nog niet grootschalig wordt toegepast."
            }, {
                "text": "De kans op succes is op dit moment kleiner dan bij eicellen en embryo's invriezen."
            }]
        }, {
            "text": "Het is nog niet duidelijk of ik het weefsel later kan gebruiken",
            "items": [{
                "text": "Het hangt af van het type kanker of het weefsel in de toekomst kan worden teruggeplaatst."
            }, {
                "text": "Ik weet niet zeker of er geen kankercellen in het weefsel zitten."
            }, {
                "text": "Het rijpen van eicellen in het laboratorium is nu nog niet mogelijk."
            }]
        }, {
            "text": "Ik wil geen operatie ondergaan",
            "items": [{
                "text": "Ik ben bang voor operaties en/of narcose."
            }, {
                "text": "Ik wil geen operatie als het niet per se hoeft."
            }]
        }]
    }]
}, {
    "text": "Gezondheid",
    "items": [{
        "text": "Argumenten tegen",
        "items": [{
            "text": "De behandeling kan mijn gezondheid en die van mijn kind schaden    ",
            "items": [{
                "text": "Ik vind de gezondheidsrisico's van de operatie te hoog."
            }, {
                "text": "Er is weinig bekend over de effecten van de behandeling op de gezondheid van het kind. "
            }, {
                "text": "Er is weinig bekend over de effecten van de behandeling op mijn gezondheid op lange termijn."
            }, {
                "text": "Mijn eicelvoorraad neemt af, hierdoor neemt de kans op vervroegde overgang toe."
            }, {
                "text": "De operatie geeft een kans van circa 1 procent op complicaties en hiermee uitstel van de kankerbehandeling."
            }]
        }]
    }]
}, {
    "text": "Kosten",
    "items": [{
        "text": "Argumenten tegen",
        "items": [{
            "text": "De behandeling kost geld",
            "items": [{
                "text": "Ik moet betalen voor de opslag van het weefsel, zo'n 60 tot 100 euro per jaar."
            }, {
                "text": "Ziekenhuiszorg gaat af van het wettelijke eigen risico en mogelijk geldt een eigen bijdrage voor medicatie. "
            }]
        }]
    }]
}, {
    "text": "Behandeling",
    "items": [{
        "text": "Argumenten voor",
        "items": [{
            "text": "De behandeling beïnvloedt mijn kankerbehandeling niet   ",
            "items": [{
                "text": "Ik kan de vruchtbaarheidsbehandeling combineren met de kankeroperatie."
            }, {
                "text": "Mijn kankerbehandeling hoeft niet uitgesteld te worden."
            }]
        }, {
            "text": "De behandeling vraagt lichamelijk en psychisch minder dan eicellen of embryo's invriezen",
            "items": [{
                "text": "Deze behandeling is psychisch minder ingrijpend dan eicellen of embryo's invriezen."
            }, {
                "text": "Ik hoef geen vaginale echo's en punctie te ondergaan."
            }]
        }, {
            "text": "De behandeling past bij mijn situatie op dit moment",
            "items": [{
                "text": "Ik heb nu geen mannelijke partner of donor nodig."
            }]
        }]
    }]
}, {
    "text": "Overtuiging",
    "items": [{
        "text": "Argumenten voor",
        "items": [{
            "text": "De behandeling past bij mijn levensovertuiging    ",
            "items": [{
                "text": "Ik mag van mijn religie geen embryo's invriezen, maar wel eierstokweefsel."
            }]
        }]
    }]
}]}

export default data
