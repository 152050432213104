import React, {Component} from 'react';

import Card from '../Card/Card';
import PijlRechts from '../Icons/PijlRechts';

import './Frontpage.scss';

class Frontpage extends Component {

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
		const props = this.props,
					page = props.page,
					cards = page.cards.map(card => <Card
						key={card.id}
						id={card.id}
						title={card.title}
						text={card.text}
						link={card.link}
						handleClickCard={props.handleOpenPage}
						/>)

		return (
			<div className="page frontpage">
			  <div className="intro">
			  	<p className="intro-text">{page.text}</p>
			  	<div className="intro-buttons">
				  	<button id="voortraject" onClick={() => props.handleOpenPage(1) }>Wel of geen vruchtbaarheidsbehoud?<PijlRechts /></button>
			  	</div>
			  </div>
			  <h3 className="page-title">{page.title}</h3>
			  <div className="page-cards">{cards}</div>
		  </div>
		)
	}
}

export default Frontpage;
