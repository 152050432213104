const data = {"text": "Wat zijn voor mij argumenten voor en tegen een eierstok verplaatsen buiten het bestralingsgebied?","items": [{
    "text": "Resultaat",
    "items": [{
        "text": "Argumenten voor",
        "items": [{
            "text": "De behandeling geeft me de kans een genetisch eigen kind te krijgen",
            "items": [{
                "text": "Ik vergroot mijn kans op een kind."
            }]
        }]
    }, {
        "text": "Argumenten tegen",
        "items": [{
            "text": "De behandeling geeft geen garantie op succes",
            "items": [{
                "text": "Er is vooraf weinig te zeggen over de kansen op een succesvolle behandeling en zwangerschap."
            }]
        }]
    }]
}, {
    "text": "Gezondheid",
    "items": [{
        "text": "Argumenten voor",
        "items": [{
            "text": "Mijn kankerbehandeling hoeft niet uitgesteld te worden",
            "items": [{
                "text": "Ik hoef niet bang te zijn dat de kanker zich (verder) verspreidt als ik bezig ben met de vruchtbaarheidsbehandeling."
            }, {
                "text": "Bestraling van eierstokken geeft risico op een vervroegde overgang, verplaatsing van mijn eierstokken verlaagt dat risico."
            }]
        }]
    }, {
        "text": "Argumenten tegen",
        "items": [{
            "text": "De behandeling kan slecht zijn voor mijn gezondheid     ",
            "items": [{
                "text": "Er is weinig bekend over de effecten op mijn gezondheid op lange termijn en de kans op zwangerschap."
            }, {
                "text": "De operatie geeft een kans van circa 1 procent op complicaties en hiermee uitstel van de kankerbehandeling."
            }]
        }]
    }]
}, {
    "text": "Behandeling",
    "items": [{
        "text": "Argumenten voor",
        "items": [{
            "text": "De behandeling sluit aan bij mijn kankerbehandeling",
            "items": [{
                "text": "Ik kan de vruchtbaarheidsbehandeling combineren met de kankeroperatie."
            }, {
                "text": "Mijn kankerbehandeling hoeft niet uitgesteld te worden."
            }]
        }, {
            "text": "Ik hoef geen hormonen in te nemen",
            "items": [{
                "text": "Deze behandeling is psychisch minder ingrijpend dan eicellen of embryo's invriezen."
            }]
        }]
    }, {
        "text": "Argumenten tegen",
        "items": [{
            "text": "Ik wil geen operatie ondergaan",
            "items": [{
                "text": "Ik ben bang voor operaties en/of narcose."
            }, {
                "text": "Ik wil geen operatie als het niet per se hoeft."
            }]
        }]
    }]
}, {
    "text": "Overtuiging",
    "items": [{
        "text": "Argumenten voor",
        "items": [{
            "text": "Ik hoef niets buiten mijn lichaam te bewaren",
            "items": [{
                "text": "Ik vind het een fijn idee dat ik mijn eierstok en eicellen in mijn lichaam houd."
            }]
        }, {
            "text": "De behandeling past bij mijn levensovertuiging",
            "items": [{
                "text": "Ik mag van mijn religie geen embryo's invriezen."
            }]
        }]
    }]
}]}

export default data
