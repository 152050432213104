import React from 'react';

import Social from '../Social/Social';
import LogoFabriek from '../Icons/LogoFabriek';
import LogoUmc from '../Icons/LogoUmc';

import './Footer.scss';

const Footer = (props) => {

		return (
		  <div className="footer">
		  	<a href="https://www.vumc.nl/zorg/expertisecentra-en-specialismen/kennis-en-zorgcentrum-genderdysforie.htm"
			  	target="_blank"
			  	rel="noopener noreferrer">
			  	<LogoUmc />
		  	</a>
			  	<LogoFabriek />
		  	<Social
					url="https://keuzehulp-vruchtbaarheidsbehoud-kanker.nl"
					title="Keuzehulp vruchtbaarheidsbehoud kanker"
					text="Wat zijn mijn opties voor vruchtbaarheidsbehoud bij kanker?"
					message="Vind het antwoord op keuzehulp-vruchtbaarheidsbehoud-kanker.nl"
		  	 />
		  </div>
		)
}

export default Footer;
