const data = {"text": "Wat zijn voor mij argumenten voor en tegen embryo's invriezen?","items": [{
    "text": "Resultaat",
    "items": [{
        "text": "Argumenten voor",
        "items": [{
            "text": "De behandeling geeft me de kans een genetisch eigen kind te krijgen",
            "items": [{
                "text": "Ik vergroot mijn kans op een kind."
            }]
        }, {
            "text": "De behandeling geeft me zekerheid over de vruchtbaarheid van mij en mijn partner",
            "items": [{
                "text": "Als het lukt om embryo's te laten ontstaan weet ik dat ik met mijn partner of een donor embryo's kan maken."
            }, {
                "text": "Omdat de bevruchting al plaats heeft gevonden kan dat niet meer fout gaan na het ontdooien van eicellen."
            }]
        }, {
            "text": "Embryo's invriezen is handiger dan eicellen invriezen",
            "items": [{
                "text": "Er is meer ervaring met het invriezen van embryo's dan eicellen."
            }, {
                "text": "De gezondheid van mijn partner maakt dat het handig is om embryo's in te vriezen in plaats van eicellen."
            }, {
                "text": "De kans van slagen is groter dan met eicellen invriezen, omdat ik al een stap verder ben in het proces."
            }]
        }]
    }, {
        "text": "Argumenten tegen",
        "items": [{
            "text": "De behandeling geeft geen garantie op succes",
            "items": [{
                "text": "Ik moet voor een succesvolle vruchtbaarheidsbehandeling meerdere eicellen kunnen laten invriezen."
            }, {
                "text": "Ik heb geen garantie op succes van invriezen."
            }, {
                "text": "Ik heb geen garantie op een levend geboren kind."
            }, {
                "text": "Er is vooraf weinig te zeggen over de kansen op een succesvolle behandeling en zwangerschap."
            }, {
                "text": "Als mijn relatie eindigt heb ik toestemming van mijn ex-partner nodig als ik de embryo's wil gebruiken."
            }]
        }]
    }]
}, {
    "text": "Gezondheid",
    "items": [{
        "text": "Argumenten tegen",
        "items": [{
            "text": "De behandeling kan schadelijk zijn voor mijn gezondheid",
            "items": [{
                "text": "De vruchtbaarheidsbehandeling kost tijd, de kanker kan zich intussen verder verspreiden."
            }, {
                "text": "Het is onduidelijk wat het langetermijneffect van de vruchtbaarheidsbehandeling op mijn kanker is."
            }, {
                "text": "De psychische belasting kan het voor mij lastig maken om afwegingen over mijn kankerbehandelingen te maken.     "
            }, {
                "text": "Bij complicaties van de punctie, zoals bloedingen of infectie, moet mogelijk mijn kankerbehandeling uitgesteld worden."
            }]
        }]
    }]
}, {
    "text": "Kosten",
    "items": [{
        "text": "Argumenten voor",
        "items": [{
            "text": "De behandeling wordt grotendeels vergoed",
            "items": [{
                "text": "De behandeling wordt als er een medische indicatie is vergoed; er geldt wel een eigen risico."
            }]
        }]
    }, {
        "text": "Argumenten tegen",
        "items": [{
            "text": "De behandeling kost geld",
            "items": [{
                "text": "De jaarlijkse kosten voor het ingevroren houden van de eicellen worden niet vergoed."
            }, {
                "text": "De kosten voor het invriezen van eicellen worden niet door alle zorgverzekeraars vergoed."
            }, {
                "text": "Ziekenhuiszorg gaat af van het wettelijke eigen risico en mogelijk geldt een eigen bijdrage voor medicatie. "
            }]
        }]
    }]
}, {
    "text": "Behandeling",
    "items": [{
        "text": "Argumenten voor",
        "items": [{
            "text": "De behandeling start snel en duurt kort",
            "items": [{
                "text": "De behandeling gaat relatief snel, binnen maximaal vier weken kan ik beginnen aan de kankerbehandeling."
            }, {
                "text": "Ik kan al tijdens de diagnosefase van mijn ziekte aan de vruchtbaarheidsbehandeling beginnen."
            }]
        }, {
            "text": "De behandeling past bij mijn situatie op dit moment   ",
            "items": [{
                "text": "We kunnen later beslissen of we de embryo's willen gebruiken."
            }]
        }]
    }, {
        "text": "Argumenten tegen",
        "items": [{
            "text": "De behandeling kost veel tijd en is voor mij lastig te plannen",
            "items": [{
                "text": "Ik moet vaak en op moeilijk planbare tijden naar het ziekenhuis voor echo's en een punctie."
            }, {
                "text": "Ik heb voor deze behandeling een mannelijke partner of donor nodig."
            }]
        }, {
            "text": "De behandeling is lichamelijk en psychisch zwaar",
            "items": [{
                "text": "Ik kan klachten krijgen zoals buikpijn, misselijkheid, hoofdpijn en vaginaal bloedverlies."
            }, {
                "text": "De punctie om de eicellen te prikken is pijnlijk en geeft een klein risico op infecties en bloedingen."
            }, {
                "text": "Als het niet snel lukt om voldoende eicellen te verzamelen levert dit voor mij en mijn partner veel stress op."
            }, {
                "text": "De hormoonstimulaties en controles van het rijpen van de eiblaasjes zijn stressvol."
            }, {
                "text": "De hormoonstimulaties maken me prikkelbaar."
            }]
        }, {
            "text": "Ik voel me niet prettig bij deze behandeling    ",
            "items": [{
                "text": "Ik wil geen extra medicatie gebruiken als dat niet noodzakelijk is."
            }, {
                "text": "Ik ben bang voor naalden."
            }]
        }]
    }]
}, {
    "text": "Overtuiging",
    "items": [{
        "text": "Argumenten voor",
        "items": [{
            "text": "Ook als mijn partner en ik de embryo's niet kunnen of willen gebruiken kan ik er iets goeds mee doen",
            "items": [{
                "text": "Als we ervoor kiezen de embryo's niet te gebruiken kunnen we ze doneren, zoals aan de wetenschap."
            }]
        }]
    }, {
        "text": "Argumenten tegen",
        "items": [{
            "text": "Ik ben bang dat hormonen mijn kanker zullen stimuleren",
            "items": [{
                "text": "Ik moet mezelf inspuiten met hormonen, dit voelt in strijd met mijn hormoongevoelige kanker."
            }, {
                "text": "Ik ben bang dat een latere zwangerschap de kans vergroot dat de hormoongevoelige kanker terugkomt."
            }]
        }, {
            "text": "Ik heb bezwaren tegen het invriezen van embryo's",
            "items": [{
                "text": "Ik zie embryo's als kinderen en wil ze niet laten maken als ik nog niet weet of ik ze wil."
            }, {
                "text": "Ik mag van mijn religie geen embryo's invriezen."
            }]
        }]
    }]
}]}

export default data
