import React, {Component} from 'react';

import Card from '../Card/Card';

import './Cardpage.scss';

class Cardpage extends Component {

  componentDidMount() {
    window.scrollTo(0, 0)
  }

	render(){
		const props = this.props,
					page = props.page,
					cards = page.cards.map(card => <Card
						key={card.id}
						id={card.id}
						title={card.title}
						text={card.text}
						link={card.link}
						handleClickCard={props.handleClickCard}
						/>)

		return (
			<div className="page cardpage">
			  <h3 className="page-title">{page.title}</h3>
			  <div className="page-cards">{cards}</div>
		  </div>
		)
	}
}

export default Cardpage;
