import React, {Component} from 'react';

import PijlRechts from '../Icons/PijlRechts';

import './Harmonica.scss';


class Harmonica extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount(){
    const {closed, level} = this.props;
    (closed.indexOf(level) > -1) ? this.setState({ status: 'initial-closed'}) : this.setState({ status: 'initial-open'});
  }

  clickHandler = () => {
    this.state.status === 'closed' || this.state.status === 'initial-closed' ? this.setState({ status: 'open'}) : this.setState({ status: 'closed'});
  }

  render(){

    const props = this.props,
          {expandables, closed, level} = props,
          {text, items} = props.data,
          expandable = expandables.indexOf(level) > -1,
          baseClass = "harmonica level" + level;

    const children = items ? items.map((item, i) => (
          <Harmonica
            key={level+'-'+i}
            data={item}
            level={level+1}
            expandables={expandables}
            closed={closed}
            />
        )) : null;

    const arrow = expandable ? <span className="arrow"><PijlRechts /></span> : null;
    const thisClass = expandable ? baseClass + " expandable " + this.state.status : baseClass;
    const thisTextClass = expandable ? "harmonica-text expandable " + this.state.status : "harmonica-text " + this.state.status;
    const thisContentClass = "harmonica-children " + this.state.status;

    return (
      <div className={thisClass} >
        <div className={thisTextClass} onClick={expandable ? this.clickHandler : undefined }>
          {text}
          {arrow}
        </div>
        <div className={thisContentClass}>
          {children}
        </div>
      </div>
    );
  };
};

export default Harmonica;
