import React from 'react';

import './Menu.scss';

const Menu = (props) => {

	const items = props.items.map((item) => (
		<li key={item.id}>
			<a href={"#"+item.id} id={item.id} onClick={props.handleClickMenu}>
				{item.name}
			</a>
			</li>
		))

  return (
        <div className={"menu " + props.status} >
        	<ul>
        		{items}
        	</ul>
        </div>
    );
}

export default Menu;
