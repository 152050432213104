import React, {Component} from 'react';

import Panel from '../Panel/Panel';
import Harmonica from '../Harmonica/Harmonica';

import './Infopage.scss';

class Infopage extends Component {

	  componentDidMount() {
	    window.scrollTo(0, 0)
	  }

	  render() {
		const props = this.props, page = props.page, slug = page.slug

		const intro = page.intro ? <Panel data={props.page.intro} status="initial-open" expandable={props.page.intro.expandable} /> : null;

		const voorwaarden = page.voorwaarden ? <Panel data={props.page.voorwaarden} status="initial-closed" /> : null;

		const harmonica = page.harmonica ?
			<div className="page-harmonica">
				<Harmonica data={page.harmonica} expandables={page.expandables} closed={page.closed} level={0} />
			</div> :
			null;

		return (
			<div className={"page infopage "+ slug}>
			  {intro}
			  {voorwaarden}
			  {harmonica}
		  </div>
		)
	}
}

export default Infopage;
