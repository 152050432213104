import React from 'react';

import PijlRechts from '../Icons/PijlRechts';

import './Card.scss';

const Card = (props) => {

		const handleClick = (event) => {
			event.stopPropagation();
			props.handleClickCard(props.link)
		}

		return (
		  <div className={"card " + props.id} onClick={handleClick}>
		    <h4 className="card-title">{props.title}</h4>
		    <p className="card-text">{props.text}</p>
		    <button className="card-button" id={props.id} onClick={handleClick}><PijlRechts /></button>
		  </div>
		)
}

export default Card;
