import React from 'react';

import Menu from '..//Menu/Menu';
import Pijl from '../Icons/Pijl';
import Hamburger from '../Icons/Hamburger';

import './Header.scss';

const Header = (props) => {

		const titleClass = props.showBackButton ? "header-title" : "header-title noback";
		const backbuttonClass = props.showBackButton ? "backbutton" : "backbutton hide";

		return (
		  <div className="header">
		  	<button className={backbuttonClass} onClick={props.handleClickBack}><Pijl /></button>
		    <h1 className={titleClass} onClick={() => props.handleOpenPage(0) }>Keuzehulp vruchtbaarheidsbehoud kanker</h1>
		    <button
		    	className="hamburgerbutton"
		    	onClick={props.handleClickHamburger}>
		    	<Hamburger />
	    	</button>
		    <Menu
          items={props.items}
          status={props.status}
          handleClickMenu={props.handleClickMenu}
        />
		  </div>
		)
}

export default Header;
