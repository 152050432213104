import React, {Component} from 'react';

import PijlRechts from '../Icons/PijlRechts';

import './Panel.scss';


class Panel extends Component {
  constructor(props) {
    super(props);
    this.state = { status: this.props.status };
  }

  clickHandler = () => {
    this.state.status === 'closed' || this.state.status === 'initial-closed' ? this.setState({ status: 'open'}) : this.setState({ status: 'closed'});
  }

  render(){
		const {title, content, expandable} = this.props.data;
		const pijl = expandable ? <span className="arrow"><PijlRechts /></span> : null;
		const clickHandler = expandable ? this.clickHandler : undefined;

		return (
		  <div className="panel">
		    <h4 className={"panel-title " + this.state.status} onClick={clickHandler}>{title} {pijl}</h4>
		    <div className={"panel-content " + this.state.status} >{content}</div>
		  </div>
		)
	}
}

export default Panel;
